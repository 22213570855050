.product-page{
    max-width: 1024px;
    padding: 0 15px 0 15px;
    margin: 96px auto;
}
.product-large{
    position: relative;
}
.product-large .browser-title{
    height: 40px;
    background-color: #0008;
    width: 100%;
    position: relative;
}
.product-large .anim{
    opacity: 0;
    transform: translateY(20px);
}
.product-large .h{
    display: block;
    text-align: center;
    margin: 36px;
}
.product-large .browser-url{
    height: 28px;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    border-radius: 28px;
    background-color: #0005;
    padding: 0 30px;
    width: 70%;
    margin-left: 5%;
    line-height: 28px;
}
.product-large .browser-url{
    font-size: 14px;
    text-decoration: none;
    color: #FFF;
}
.product-large iframe{
    border:none;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
}
.product-large .iframe{
    background: #FFFFFF;
    box-shadow: 0 0 3px 0 #00000044;
}
.product-large .vmoe{
    height: 50vh;
}
.product-large .bilichat {
    width:40%;
    height: 100%;
    position: absolute;
    top:0;
    right:15px;
}
.product-large a{
    text-decoration: none;
    color: #888;
}
.product-large .author-center{
    color: #888;
    font-size: 12px;
}

.product-page .title {
    opacity: 0;
    transform: translateX(-20px);
    color: #000;
    font-size: 32px;
    margin-bottom: 28px;
}
.product-page .quote {
    border-left: 1px solid #aaa;
    opacity: 0;
    transform: translateX(-20px);
    color: #888;
    font-size: 14px;
    margin: 0 1px 16px;
    padding-left: 16px;
    position: relative;
}
.product-page .quote p{
margin-top: 0.3em;
margin-bottom: 0.3em;
}
.product-page .quote p:first-child{
margin-top: 0;
}
.product-page .quote p:last-child{
margin-bottom: 0;
}

.product-page .author{
    color: #888;
    padding-left: 16px;
    font-size: 12px;
}
.product-page .more{
    color: #888;
    padding-left: 16px;
    font-size: 1rem;
    text-decoration: none;
    float:right;
}
/*
.product-page .title::after{
    content: "";
    display: block;
    height: 2px;
    width: 70%;
    margin-top: 8px;
    background: #d9d9d9;
}*/
.product-page .content{
    color: #000;
    line-height: 1.5;
    opacity: 0;
    transform: translateX(-20px);
}