h1,h2,h3,h4,h5,h6{
  font-weight: normal;
  margin:0;
}
.App{
  background: #FFF;
}
header{
  height: 48px;
  display: flex;
  align-items: center;
  background: #20232a;
  color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
header .flex-filler{
  flex-grow: 1;
}
header .mark-github{
  height: 24px;
  fill:#FFFFFF;
  margin: 0 1.5rem;
}
header .logo-text{
  font-weight: normal;
  margin: 0 1.5rem;
  font-size: 18px;
}
.hero{
  position: relative;
  height: 100vh;
  color: #FFFFFF;
  background: #000;
  z-index: 0;
}
main{
  position: relative;
  z-index: 1;
  background: #FFF;
  padding: 1px 0;
}
footer{
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #20232a;
  color: #6b6d76;
  position: relative;
  width: 100%;
  z-index: 10;
}
.heroCenter{
  position: absolute;
  top:0;
  left:0;
  background: rgba(0,0,0,0.3);
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-variant: petite-caps;
  transition: background-color 1s,filter 1s,transform 1s;
}
.heroCenterText{
  display: flex;
  align-items: center;
  justify-content: center;
  font-variant: petite-caps;
  transition: background-color 1s,filter 1s,transform 1s,text-shadow 1s;
  pointer-events: none;
  text-shadow: 0 0 20px black, 0 0 20px black;
}
.heroCenter *{
  text-align: end;
  padding: 8px 16px;
}
.right-border{
  border-right: 0.05em solid #FFFFFF;
}

.heroBackground{
  background: #000;
  position: fixed;
  top:0;
  left:0;
  z-index: -2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter:blur(2px);
  animation-name: fadeIn; /*动画名称*/
  animation-duration: 3s; /*动画持续时间*/
  animation-iteration-count: 1; /*动画次数*/
  animation-delay: 0s;
  transition: filter 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0; /*初始状态 透明度为0*/
  }
  50% {
    opacity: 0; /*中间状态 透明度为0*/
  }
  100% {
    opacity: 1; /*结尾状态 透明度为1*/
  }
}

.canhover .heroCenter{
  pointer-events: none;
}

.canhover:hover .heroBackground{
  filter: none;
  transition-delay: 0.3s;
}
.canhover:hover .heroCenter{
  background: transparent;
  filter:blur(4px);
  transition-delay: 0.3s;
}
.canhover:hover .heroCenterText{
   transform: scale(1.01);
  text-shadow: none;
   transition-delay: 0.3s;
 }