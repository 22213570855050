.logs-root{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
}

.spider{
    margin: 0.5em;
    padding: 0.3em;
    width: 8vw;
    height: 100px;
    overflow: hidden;
    transition: width 0.5s,height 0.5s,transform 0.5s;
    animation: show 1s,darken 2s;
    align-self: center;
}
.spider h4{
    animation: show 0.3s,darken 1s,dark 2s;
    opacity: 0.7;
    font-size: small;
}
.spider h5{
     animation: show 0.3s,darken 1s;
     opacity: 1;
 }

@keyframes show
 {
     from {opacity: 0;}
     to {opacity: 1;text-shadow: 0 0 5px #FFF}
 }
@keyframes darken
{
    from {opacity: 1;text-shadow: 0 0 5px #FFF}
    to {opacity: 1;text-shadow: 0 0 0 #FFF0}
}
@keyframes dark
{
    from{opacity:1}
    to{opacity:0.7}
}
